$defaultFontFamily: 'Ubuntu', sans-serif;

$defaultFontSize: 17px;

$normalFontSize: 1em;
$xSmallFontSize: 0.7058em;    //12px
$smallFontSize: 0.8823em;     //15px
$mediumFontSize: 1.1764em;    //20px
$largeFontSize: 1.4705em;     //25px
$xLargeFontSize: 1.6570em;    //28px
$bigFontSize: 2.3529em;       //40px
