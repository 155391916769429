.button {
    @include defaultButton();
    @include buttonColor();
    
    &--w-2 {
        @include buttonColor($textColor: $lightTextColor1, $borderColor: $lightTextColor1, $emTextColor: $emTextColor2, $emBackground: $lightBackColor1);
    }

    &--2 {
        @include buttonColor($textColor: $emTextColor2, $borderColor: $emTextColor2, $emTextColor: $lightTextColor1, $emBackground: $emBackColor2);
    }

    &--3 {
        @include buttonColor($textColor: $emTextColor3, $borderColor: $emTextColor3, $emTextColor: $lightTextColor1, $emBackground: $emBackColor3);
    }

    &--b {
        @include buttonColor($textColor: #333333, $borderColor: #333333, $emTextColor: $lightTextColor1, $emBackground: #333333);
    }

    &--arrow-left {
        &:before {
            content: "\f060";
        }
    }
}