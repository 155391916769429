.footer-logo {
    padding-bottom: $defaultPaddingY;
    padding-top: $defaultPaddingY;
}

.footer-nav {
    background-color: $darkBackColor1;
    color: $lightTextColor1;
    padding-bottom: $defaultPaddingY;
    padding-top: $smallPaddingY;
    text-align: right;

    &__menu {
        margin-bottom: 0;

        li {
            display: inline;
            font-size: $xSmallFontSize;
            margin-right: 4px;

            &::after {
                content: "|";
                margin-left: 8px;
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    content: "";
                    margin-left: 0;
                }
            }
        }
    }
}