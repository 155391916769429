$backColor: #ffffff;
$menuBackColor: #ffffff;
$footerBackColor: #222222;
$emBackColor1: #ff0000;
$emBackColor2: #500b5c;
$emBackColor3: #95113c;
$lightBackColor1: #ffffff;
$lightBackColor2: #f1f1f1;
$lightBackColor3: #a7a7a7;
$darkBackColor1: #3b3a3d;
$darkBackColor2: #535353;

$defaultTextColor: #000000;
$emTextColor1: #ff0000;
$emTextColor2: #500b5c;
$emTextColor3: #95113c;
$lightTextColor1: #ffffff;
$lightTextColor2: #f1f1f1;
$lightTextColor3: #a7a7a7;
$darkTextColor1: #3b3a3d;
$darkTextColor2: #535353;


$defaultButtonTextColor: #ff0000;
$defaultButtonBackColor: transparent;
$defaultButtonEmTextColor: #ffffff;
$defaultButtonEmBackColor: #ff0000;

