@mixin imageBackgroundCover($width: 100%, $height: 100%) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: $height;
    width: $width;
    -webkit-backface-visibility: hidden;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@mixin containerPaddingLeft($screenSize, $offset: 0px) {
    padding-left: calc(((100vw - #{$screenSize}) / 2) + 15px + #{$offset});
}

@mixin containerPaddingRight($screenSize) {
    padding-right: calc(((100vw - #{$screenSize}) / 2) + 15px);
}

/* Headers borders utils */
@mixin withBorderBottom ($borderColor: $emTextColor1, $borderWidth: 3px, $width: 40px, $marginBottom: 30px, $paddingBottom: 30px) {
    // Reset
    margin: 0;
    padding: 0;

    margin-bottom: $marginBottom;
    padding-bottom: $paddingBottom;
    position:relative;

    &:after {
        // Reset
        border: none;

        border-bottom: $borderWidth solid $borderColor;
        content: " ";
        position: absolute;
        bottom: 0;
        width: $width;
    }
}

@mixin withBorderBottomLeft ($borderColor: $emTextColor1, $borderWidth: 3px, $width: 40px, $marginBottom: 30px, $paddingBottom: 30px) {
    @include withBorderBottom($borderColor: $borderColor, $borderWidth: $borderWidth, $width: $width,$marginBottom: $marginBottom, $paddingBottom: $paddingBottom);

    &:after {
        left: 0;
        right: unset;
    }
}

@mixin withBorderBottomRight ($borderColor: $emTextColor1, $borderWidth: 3px, $width: 40px, $marginBottom: 30px, $paddingBottom: 30px) {
    @include withBorderBottom($borderColor: $borderColor, $borderWidth: $borderWidth, $width: $width,$marginBottom: $marginBottom, $paddingBottom: $paddingBottom);

    &:after {
        left: unset;
        right: 0;
    }
}

@mixin withArrowRight($height: 30px, $width: 15px, $color: $defaultTextColor) {
    &:after {
        content: ' ';
        height: 0;
        border-top: ($height / 2) solid transparent;
        border-bottom: ($height / 2) solid transparent;
        border-left: $width solid $color;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
    }
}

@mixin withArrowLeft($height: 30px, $width: 15px, $color: $defaultTextColor) {
    &:after {
        content: ' ';
        height: 0;
        border-top: ($height / 2) solid transparent;
        border-bottom: ($height / 2) solid transparent;
        border-right: $width solid $color;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
    }
}