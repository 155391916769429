.login {
    padding-bottom: $largePaddingY;
    padding-top: $largePaddingY;

    @include md {
        padding-bottom: $xLargePaddingY;
        padding-top: $xLargePaddingY;
    }

    &__title {
        color: $emTextColor1;
        font-size: $xLargeFontSize;
        font-weight: 700;

        @include md {
            font-size: $bigFontSize;;
        }

        @include withBorderBottomLeft($borderColor: $lightTextColor3, $borderWidth: 8px, $width: 60px);
    }

    &__form {
        padding-bottom: $defaultPaddingY;
        padding-top: $defaultPaddingY;
    }
}