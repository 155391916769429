.description {
    padding-bottom: $defaultPaddingY;
    padding-top: $defaultPaddingY;

    @include md {
        padding-bottom: $mediumPaddingY;
        padding-top: $mediumPaddingY;
    }

    &__item {
        background-color: $lightBackColor2;
        margin-bottom: 5px;
        padding-bottom: $smallPaddingY;
        padding-top: $smallPaddingY;

        &__header {

            &--expanded {

                .description__item__intro {
                    @include withBorderBottomLeft($borderColor: $lightTextColor3, $borderWidth: 8px, $width: 60px, $marginBottom: 20px, $paddingBottom: 20px);
                }
            }
        }

        &__number-wrapper {
            position: relative;
        }

        &__number {
            align-content: center;
            align-items: center;
            background-color: $emBackColor1;
            color: $lightTextColor1;
            display: flex;
            font-weight: 700;
            height: 50px;
            justify-content: center;
            left: -25px;
            line-height: 1;
            max-width: unset;
            text-align: center;
            top: 0;
            width: 50px;  

            @include md {
                position: absolute;
            }
        }

        &__title {
            color: $emTextColor1;
            font-size: $xLargeFontSize;
            font-weight: 700;

            @include md {
                font-size: $bigFontSize;;
            }
        }

        &__intro {
            font-weight: 700;
            text-transform: uppercase;

            @include md {
                font-size: $mediumFontSize;
            }
        }

        &__toggler {
            color: $emTextColor2 !important;
            cursor: pointer;
            font-size: $largeFontSize;

            @include md {
                font-size: $bigFontSize;
            }

            i {
                @include defaultTransition();
            }

            &--expanded {
                i {
                    transform: rotate(-180deg);

                    @include defaultTransition();
                }
            }
        }

        &__detail {
            display: none;
            font-size: $smallFontSize;

            ul {
              list-style: disc;
              padding-left: $mediumPaddingX;
            }

            a {
                color: $emTextColor1;
                text-decoration: underline;
            }

            &__focus{
                margin-bottom: $defaultMarginY;
                padding-bottom: $defaultPaddingY;
                padding-top: $defaultPaddingY;

                @include md {
                    border: 2px solid $lightTextColor1;
                }

                img {
                    width: auto;
                    margin-bottom: $defaultMarginY;
                }

                h3 {
                    color: $emTextColor1;
                    font-size: $largeFontSize;
                    font-weight: 700;
                    text-transform: uppercase;

                    @include md {
                        font-size: $xLargeFontSize;
                        margin-bottom: 0;
                    }
                }

                ul {
                    list-style: disc;
                    margin-bottom: 0;

                    margin-left: $defaultMarginX;

                    @include md {
                        margin-left: 0;
                    }
                }
            }

            &__video{
                margin-bottom: $mediumMarginY;
                margin-top: $defaultMarginY;

                &__title{
                    font-weight: 700;
                    margin-top: $mediumMarginY;
                    text-align: center;
                    text-transform: uppercase;
    
                    @include md {
                        font-size: $mediumFontSize;
                    }
                }
            }
        }
    }
}