.header {
    background-color: $emBackColor1;
    height: 80px;

    &__inner {
        height: 100%;
    }

    &__logo {
        position: relative;

        @include lg {
            @include containerPaddingLeft($screenSize: 960px);
        }

        @include xl {
            @include containerPaddingLeft($screenSize: 1140px);
        }

        img {
            background-color: $emBackColor1;
            position: absolute;
            top: 5px;
            width: 135px;
            z-index: 999;

            @include md {
                top: 20px;
                width: 240px;
            }
        }
    }

    &__navigation {
        display: flex;
        background-color: $emBackColor3;
        color: $lightTextColor1;
        flex-direction: column;
        justify-content: center;
        font-size: $smallFontSize;
        font-weight: 700;
        text-align: right;
        text-transform: uppercase;

        @include lg {
            @include containerPaddingRight($screenSize: 960px);
        }

        @include xl {
            @include containerPaddingRight($screenSize: 1140px);
        }

        i {
            margin-right: 5px;
        }

        span {
            display: none;

            @include md {
                display: inline;
            }
        }
    }
}
