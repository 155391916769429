.presentation {
    background-color: $emBackColor2;
    background-image: url('../img/illustration.svg');
    text-align: center;
    padding-bottom: $defaultPaddingY;
    padding-top: $defaultPaddingY;

    @include imageBackgroundCover($height: auto);

    @include md {
        padding-bottom: $mediumPaddingY;
        padding-top: $mediumPaddingY;
    }

    &__title {
        /* margin-bottom: $defaultMarginY;

        @include md {
            margin-bottom: $mediumMarginY;
        } */

        img {
            @include md {
                width: 75%
            }

            @include lg {
                width: 65%
            }
        }
    }

    &__item {
        margin-bottom: $defaultMarginY;

        @include md {
            margin-bottom: 0;
        }

        h3 {
            color: $lightTextColor1;
            font-size: $mediumFontSize;
            font-weight: 500;
            line-height: 1.2;

            @include md {
                font-size: $largeFontSize;
            }
        }

        span {
            color: $emTextColor1;
            display: block;
        }
    }

    &__button {
        margin-top: $defaultMarginY;

        @include md {
            margin-top: $mediumMarginY;
        }
    }
}