.legal {
    padding-bottom: $largePaddingY;
    padding-top: $largePaddingY;

    @include md {
        padding-bottom: $xLargePaddingY;
        padding-top: $xLargePaddingY;
    }

    &__title {
        color: $emTextColor1;
        font-size: $xLargeFontSize;
        font-weight: 700;

        @include withBorderBottomLeft($borderColor: $lightTextColor3, $borderWidth: 8px, $width: 60px);

        @include md {
            font-size: $bigFontSize;;
        }
    }

    &__subtitle {
        color: $emTextColor2;
        font-size: $mediumFontSize;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__question {
        color: $emTextColor1;
        font-size: $defaultFontSize;
        font-weight: 700;
        text-transform: uppercase;
    }
}