.form {
    label {
        padding-top: 5px;
        text-transform: uppercase;
    }

    input, 
    textarea {
        padding-bottom : 10px;
        padding-left : 5px;
        padding-right : 5px;
        padding-top : 10px;
    }

    textarea {
        resize: none;
    }

    .form-control {
        border: 1px solid d0d0d0;
        border-radius: 0px;

        &:focus {
            box-shadow: none;
            border-color: $darkTextColor1;
        }

        &::placeholder{
            text-transform: uppercase;
            font-weight : 600;
        }
    }

    .form-hint {
        color: $darkTextColor2;
        display: block;
        padding-top: 5px;
    }

    .form-radio {
        label {
            margin-left: 5px;
            margin-right: 15px;
        }
    }
}