.statistics {

    padding-bottom: $largePaddingY;
    padding-top: $largePaddingY;

    @include md {
        padding-bottom: $xLargePaddingY;
        padding-top: $xLargePaddingY;
    }

    &__title {
        color: $emTextColor1;
        font-size: $xLargeFontSize;
        font-weight: 700;

        @include md {
            font-size: $bigFontSize;
        }

        @include withBorderBottomLeft($borderColor: $lightTextColor3, $borderWidth: 8px, $width: 60px);
    }
    &__subtitle {
        color: $emTextColor1;
        font-size: $largeFontSize;
        font-weight: 700;

        @include md {
            font-size: $xLargeFontSize;
        }

        @include withBorderBottomLeft($borderColor: $lightTextColor3, $borderWidth: 8px, $width: 60px);
    }
    h3{
        color: $emTextColor1;
        font-size: $mediumFontSize;

        @include md {
            font-size: $mediumFontSize;
        }
        @include withBorderBottomLeft($borderColor: $lightTextColor3, $borderWidth: 4px, $width: 60px, $marginBottom: 30px, $paddingBottom: 10px);
        
    }
    &_bloc {
      padding-top: $mediumPaddingY;
      padding-bottom: $mediumPaddingY;
      border-bottom: 1px solid $lightBackColor3;
    }


    &__count {
        background-color: $emTextColor2;
        color: $lightTextColor1;
        margin-bottom: $defaultMarginY;
        padding-bottom: $smallPaddingY;
        padding-top: $smallPaddingY;
        text-align: center;

        @include md {
            font-size: $mediumFontSize;;
        }
    }

    &__global {
        margin-bottom: $defaultMarginY;

        @include md {
            margin-bottom: $largeMarginY;
        }
    }

    &__region {
        border: 2px solid $lightTextColor3;
        padding-bottom: $defaultPaddingY;
        padding-top: $defaultPaddingY;
        margin-top: $mediumMarginY;

        &__header {
            background-color: $lightBackColor3;
            color: $lightTextColor1;
            height: 80px;
            line-height: 1;
            padding-bottom: $smallPaddingY;
            padding-left:$defaultPaddingX;
            padding-right:$defaultPaddingX;
            padding-top: $smallPaddingY;

            span {
                font-size: $xSmallFontSize;
            }

            h3 {
                font-size: $mediumFontSize;
            }

            &--left {
                text-align: left;

                @include md {
                    margin-left: -20px;

                    @include withArrowRight($height: 80px, $width: 15px, $color: $lightBackColor3);
                }

                h3 {
                    @include withBorderBottomLeft($borderColor: $emTextColor1, $borderWidth: 8px, $width: 120px, $marginBottom: 0, $paddingBottom: 10px);
                }
            }

            &--right {
                text-align: left;

                @include md {
                    margin-right: -20px;
                    text-align: right;

                    @include withArrowLeft($height: 80px, $width: 15px, $color: $lightBackColor3);
                }

                h3 {
                    @include withBorderBottomLeft($borderColor: $emTextColor2, $borderWidth: 8px, $width: 120px, $marginBottom: 0, $paddingBottom: 10px);
                    
                    @include md {
                        text-align: right;

                        @include withBorderBottomRight($borderColor: $emTextColor2, $borderWidth: 8px, $width: 120px, $marginBottom: 0, $paddingBottom: 10px);
                    }
                }
            }

            &__icon {
                display: none;
                width: auto;

                @include md {
                    display: inline-block;
                    margin-top: $defaultMarginY;
                }
            }
        }

        &__filter {
            margin-top: $defaultMarginY;

        }
    }

    &__contribution {
        padding-bottom: $defaultPaddingY;
        padding-top: $defaultPaddingY;
        text-align: center;
    }
}