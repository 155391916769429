@mixin defaultButton($fontSize: $defaultFontSize, $marginLeft: 0, $marginRight: 0, $marginY: 5px) {
    border: none;
    cursor: pointer;
    display: inline-block;
    font-family: $defaultFontFamily;
    font-size: $fontSize;
    font-weight: 700;
    line-height: 1;
    margin-bottom: $marginY;
    margin-left: $marginLeft;
    margin-right: $marginRight;
    margin-top: $marginY;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    position: relative;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;

    @include defaultTransition();

    @include md {
        font-size: 23px;
    }

    &:before {
        content: "\f061";
        display: inline-block;
        font-family: 'Font Awesome 5 Free';
        font-style: normal;
        font-size: inherit;
        font-variant: normal;
        font-weight: 900;
        line-height: 1;
        margin-right: 5px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }
}

@mixin buttonColor($textColor: $defaultButtonTextColor, $borderColor: $defaultButtonTextColor,  $backgroundColor: $defaultButtonBackColor, $emTextColor: $defaultButtonEmTextColor, $emBackground: $defaultButtonEmBackColor) {
    background-color: $backgroundColor;
    border: 2px solid $borderColor;
    color: $textColor !important;

    &:hover,
    &:focus {
        background-color: $emBackground;
        color: $emTextColor !important;
    }

    &:visited { color: $textColor; }
}
