.hero {
    &__image {
        @include imageBackgroundCover();
    }

    &__caption {
        background-color: rgba(255,255,255,.95);
        padding-bottom: $mediumPaddingY;
        padding-top: $mediumPaddingY;
        position: relative;
        
        @include md {
            padding-bottom: $largePaddingY;
            padding-top: $largePaddingY;
        }

        @include lg {
            padding-right: 8.33%;
            padding-top: $xLargePaddingY;
            @include containerPaddingLeft($screenSize: 960px, $offset: 30px);
        }

        @include xl {
            @include containerPaddingLeft($screenSize: 1140px, $offset: 30px);
        }

        h1 {
            color: $emTextColor2;
            font-size: $xLargeFontSize;
            font-weight: 700;

            @include md {
                font-size: $bigFontSize;;
            }
        }

        h2 {
            color: $emTextColor1;
            font-size: $defaultFontSize;
            font-weight: 700;
            text-transform: uppercase;

            @include md {
                font-size: $mediumFontSize;
            }
        }

        p, ul {
            font-size: $smallFontSize;
            font-weight: 300;

            @include md {
                font-size: $defaultFontSize;;
            }
        }

      ul {
        padding-left: $mediumPaddingX;
        list-style: disc;
      }

        &__icon {
            color: $emTextColor1;
            font-size: $largeFontSize;

            @include md {
                font-size: 40px;
                position: absolute;
                top: 80%;
                right: -20px;
            }

            @include lg {
                font-size: 80px;
                top: 75%;
                right: -40px;
            }
        }
    }
}